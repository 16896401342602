import { ValuesPerEnvironment, Environment, extractConfigValuesBasedOnEnvironment } from './configEnvironment';

export interface Config {
  awsWafCaptchaApiKey: string;
  amplitudeExperimentApiKey: string;
  amplitudeExperimentFlagsServer: string;
  amplitudeExperimentServer: string;
  environment: Environment;
  googleMapsApiKey: string;
  intercomFeedbackSurveyId: string;
  locationServiceApiKey: string;
  locationServiceUri: string;
  microsoftClarityEnabled: boolean;
  segmentApiHost: string;
  segmentCdnUrl: string;
  segmentKey: string;
  firebaseEventsRoot: string;
}

const config: ValuesPerEnvironment<Config> = {
  awsWafCaptchaApiKey:
    'x3b0iGT18JlgV2YSvFyatr49VoMM72ZmSrN/Vd/ofO7ZWAoZziNqucXTtrWYVZ4qSoNwmD4ConCNSANv07OHDKXQgdg5RUfB6OjpYilX5lRn0+bERQdMGlvOiMjN8/mB6Qoc31LqRq0/e0Yy7TegIsjWNtuEWXRTEy44hriOHhzi1g7CjCQTiZNBgORWiVq5xqhmrGgigYSdNpSJ9VJFZYgjP1AcFUI28QD+sXRjjGBacJcg88r7I62tsuSg1OfHIPEj73eVvaYQsqe66jCXyrQVCQA+FQ8Zknuj3jupLH1b9hTpClsRkOcXIyR4FbqWl42j4oyzOuDiZmBz4Iu+xqrA0z+IVzgVf6xRMt7O/6PvJBOu1ZhyHmWNdPM0MDaosVQMb38VEzw+CSNKNqS5fIdVeUYY0JwPaFcgIZUNXiXiQsUi8rJM7Ah0rmQJzhEsSstcdh6z+A865Xnx2bYCIJWKOlnz00Op43gyfGMW/gEFNby6+9LvsKSNGGr8VZAGt6Sy2MMef8SLyGY2faIFCCpJNS12fMPaUYK1XNoXcmE1hQXvRqqr3G5DQcFkA0/1H1gKIjA7CrxVtTNhcCpWrr09REFLPb5cTdXRrvut91JkCJrabYEIZOfBMjf/Qh3Z2c9sDmNOyPSazODj0HGwuuE9UssbPyRlMpeYDDIzw3U=_1_1',
  amplitudeExperimentApiKey: {
    dev: 'client-VBfZGm3gUxArOnypZ2EvbaibP8F9niEk',
    stage: 'client-VBfZGm3gUxArOnypZ2EvbaibP8F9niEk',
    prod: 'client-duj6ZiTMbFQqhVN4EDm9OgNyCrWtMqU0'
  },
  amplitudeExperimentFlagsServer: 'https://p.withjoy.com/ampflags',
  amplitudeExperimentServer: 'https://p.withjoy.com/ampexp',
  environment: {
    dev: 'dev',
    stage: 'stage',
    prod: 'prod'
  },
  intercomFeedbackSurveyId: {
    dev: '40137453',
    stage: '40137453',
    prod: '40068519'
  },
  googleMapsApiKey: 'AIzaSyB9ePoqHL2N2xqOhy2H_7vjDokVDIq8-fE',
  microsoftClarityEnabled: {
    dev: false,
    stage: false,
    prod: true
  },
  // This AWS WAF CAPTCHA key is valid only for *.withjoy.com domains. It will not work on localhost.
  segmentApiHost: 'p.withjoy.com/segapi',
  segmentCdnUrl: 'https://p.withjoy.com/segcdn',
  segmentKey: {
    dev: '2xZJ98aly8fmfFDB9msDQlXsyVGVpUby',
    stage: '2xZJ98aly8fmfFDB9msDQlXsyVGVpUby',
    prod: 'oHWqP4BrVeBDl1FxCTC45FfLZ0qnySFw'
  },
  locationServiceApiKey: 'AIzaSyBD9m09Y2FYd3D98xvj04ujCjbiHLN2pYg',
  locationServiceUri: {
    dev: 'https://dev.withjoy.com/services/location',
    stage: 'https://stage.withjoy.com/services/location',
    prod: 'https://withjoy.com/services/location'
  },
  firebaseEventsRoot: 'https://joyevents.firebaseio.com'
};

let builtConfig: Config;
export function getConfig(): Config {
  if (!builtConfig) {
    builtConfig = extractConfigValuesBasedOnEnvironment(config);
  }

  return builtConfig;
}
